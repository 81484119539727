<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<div class="filter-item">
					<label class="label">物流公司/编码:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.keyword"
						placeholder="请输入物流公司名称/编码"
						clearable
					></el-input>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="width:100px;" @click="getList">查询</el-button>
				</div>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column type="index" width="50"></el-table-column>
				<el-table-column prop="logisticsCompanyName" label="物流公司名称" width="120"></el-table-column>
				<el-table-column prop="logisticsCompanyCode" label="物流公司编码" width="220"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import {
	companyList
} from '@/api/setting'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			searchTime: '',  //时间
			searchData: {
				keyword: '',
			},
			loading: false,
			tableData: [],
		}
	},
	async created () {
		this.getList();
	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			try {
				let data = this.searchData;
				let result = await companyList(data);
				this.tableData = result.data;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.option-item {
	/deep/.el-input {
		width: 150px !important;
	}
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
</style>
